(function(){

	var module = 'news';
	var $window = window;
	var $container = $('#interior-novedades');
	var $btn_back = $('.btn-back');

	setBtnBack($btn_back);

	function setBtnBack(element){
		element.on('click', function goBack(event){
			event.preventDefault();
			$window.location.href = document.referrer;
		});
	};

})();